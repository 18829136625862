import {
  AssetOwnerShip,
  AutoGeneratorType,
  CatrastalType,
  City,
  Country,
  ElectricitySupplyService,
  ElectricitySupplyServiceAgreement,
  EnerbitPagination,
  Estate,
  ExportEnergy,
  ExportEnergyGenerationType,
  PortafolioRiskUserType,
  QualityGroup,
  ServiceProvider,
  ServiceStatus,
  SocialStratum,
  SpecialAreaType,
  SpecialCondition,
  State,
  SubsistenceConsumption,
  SuiSocialStratum,
  TensionLevel,
  ZoneSui,
} from "@enerbit/base";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AutoCompleteInfo } from "../../models/autocomplete-info/AutoCompleteInfo";
import { ElectricityMarketInfo } from "../../models/electricity-market-info/ElectricityMarketInfo";
import { Retailer } from "../../models/electricity-market-info/Retailer";
import { EnerbitElectricitySupplyServiceAgreement } from "../../models/enerbit-electricity-supply-service-agreement/EnerbitElectricitySupplyServiceAgreement";
import { Frontier } from "../../models/frontier/frontier";
import { Lead } from "../../models/lead/leads";
import { Market } from "../../models/market/market";
import { CreateServiceState } from "../../models/states/CreateServiceState";
import {
  createEstate,
  getEnerbitElectricitySupplyServiceAgreements,
  getServiceProviders,
  getSocialStratums,
} from "../actions/accounts/accounts.actions";
import {
  getElectricityMarketInfo,
  getMarkets,
  getRetailers,
} from "../actions/electricity-market-info/electricity-market-info.actions";
import {
  createElectricitySupplyService,
  createEnerbitElectricitySupplyService,
  getAssetOwnership,
  getAutoGeneratorTypes,
  getAutocompleteEssCreationInfo,
  getCatastralTypes,
  getElectricitySupplyServiceAgreement,
  getExportEnergy,
  getExportEnergyGenerationTypes,
  getPortafolioRiskUserTypes,
  getQualityGroups,
  getServiceStatus,
  getSpecialAreaTypes,
  getSpecialConditions,
  getSubsistenceConsumptions,
  getSuiSocialStratums,
  getTensionLevel,
  getZonesSui,
  updateElectricitySupply,
} from "../actions/electricity-supply-service/electricity-supply-service.actions";
import { getFrontier } from "../actions/field-service-certifications/field-service-certifications.actions";
import {
  getCities,
  getCountries,
  getStates,
} from "../actions/geopolitics/geopolitics.actions";
import { getLeads } from "../actions/leads/leads.actions";

const initialState: CreateServiceState = {
  currentStep: 0,
  isLoadingCities: false,
  isLoadingCountries: false,
  isLoadingStates: false,
  isLoadingProviders: false,
  isLoadingSocialStratums: false,
  isLoadingCatastralTypes: false,
  isLoadingSuiSocialStratums: false,
  isLoadingElectricityMarketInfo: false,
  isLoadingAssetOwnership: false,
  isLoadingTensionLevel: false,
  isLoadingSubsistenceConsumption: false,
  isLoadingElectricitySupplyServiceAgreement: false,
  isLoadingEnerbitElectricitySupplyServiceAgreements: false,
  isLoadingRetailers: false,
  isLoadingPortafolioRiskUserType: false,
  isLoadingQualityGroups: false,
  isLoadingZoneSui: false,
  isLoadingSpecialConditions: false,
  isLoadingSpecialAreaTypes: false,
  isLoadingLeads: false,
  isLoadingFrontier: false,
  isLoadingAutocompleteInfo: false,
  isLoadingMarket: false,
  isLoadingAutoGeneratorTypes: false,
  isCreatingElectricitySupplyService: false,
  isLoadingExportEnergyGenerationTypes: false,
  isLoadingServiceStatus: false,
  isCreatingEstate: false,
  isLoadingExportEnergy: false,
  wasSuccesfullyCreated: false,
  isCreatingEnerbitElectricitySupply: false,
  paginationTensionLevel: [],
  paginationAssetOwnership: [],
  exportEnergy: [],
  serviceStatus: [],
  exportEnergyGenerationTypes: [],
  autogeneratorTypes: [],
  specialAreaTypes: [],
  specialConditions: [],
  zonesSui: [],
  qualityGroups: [],
  suiSocialStratum: [],
  catastralTypes: [],
  subsistenceConsumption: [],
  portafolioRiskUserTypes: [],
  contractInformationAutoCompleted: false,
  estateInformationAutoCompleted: false,
  regulatoryInformationAutoCompleted: false,
  technicalInformationAutoCompleted: false,
  isUpdateCregSuscribers: false,
  wasUpdateCregSuscribers: null,
  frontier: [],
};

const createServiceSlice = createSlice({
  name: "createService",
  initialState: initialState,
  reducers: {
    onNextPageRequested: (state) => {
      state.currentStep += 1;
    },
    onPreviousPageRequested: (state) => {
      state.currentStep -= 1;
    },
    onNavigateToSpecificPage: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    onCleanForm: (state) => {
      state.wasSuccesfullyCreated = false;
      state.currentStep = 0;
      state.paginationLeads = undefined;
      state.electricitySupplyServiceCreated = undefined;
      state.estateCreated = undefined;
      state.autocompleteInfo = undefined;
      state.contractInformationAutoCompleted = false;
      state.estateInformationAutoCompleted = false;
      state.regulatoryInformationAutoCompleted = false;
      state.technicalInformationAutoCompleted = false;
    },
    onContractInformationAutoCompleteChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.contractInformationAutoCompleted = action.payload;
    },
    onEstateInformationAutoCompleteChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.estateInformationAutoCompleted = action.payload;
    },
    onRegulatoryInformationAutoCompleteChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.regulatoryInformationAutoCompleted = action.payload;
    },
    onTechnicalInformationAutoCompleteChanged: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.technicalInformationAutoCompleted = action.payload;
    },
    setCleanVars: (state) => {
      state.wasUpdateCregSuscribers = null;
    },
    cleanStoreCreateService: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // getCountries
      .addCase(getCountries.pending.type, (state, _action) => {
        state.isLoadingCountries = true;
        state.paginationDataCountries = undefined;
        state.paginationDataStates = undefined;
        state.paginationDataCities = undefined;
      })
      .addCase(
        getCountries.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Country>>) => {
          state.isLoadingCountries = false;
          state.paginationDataCountries = action.payload;
        }
      )
      .addCase(getCountries.rejected.type, (state, _payload) => {
        state.isLoadingCountries = false;
      })
      // getStates
      .addCase(getStates.pending.type, (state, _action) => {
        state.isLoadingStates = true;
        state.paginationDataStates = undefined;
        state.paginationDataCities = undefined;
      })
      .addCase(
        getStates.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<State>>) => {
          state.isLoadingStates = false;
          state.paginationDataStates = action.payload;
        }
      )
      .addCase(getStates.rejected.type, (state, _action) => {
        state.isLoadingStates = false;
      })
      // getCities
      .addCase(getCities.pending.type, (state, _action) => {
        state.isLoadingCities = true;
        state.paginationDataCities = undefined;
      })
      .addCase(
        getCities.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<City>>) => {
          state.isLoadingCities = false;
          state.paginationDataCities = action.payload;
        }
      )
      .addCase(getCities.rejected.type, (state, _action) => {
        state.isLoadingCities = false;
      })
      // getSuiSocialStratums
      .addCase(getSuiSocialStratums.pending.type, (state, _action) => {
        state.suiSocialStratum = [];
        state.isLoadingSuiSocialStratums = true;
      })
      .addCase(
        getSuiSocialStratums.fulfilled.type,
        (state, action: PayloadAction<SuiSocialStratum[]>) => {
          state.isLoadingSuiSocialStratums = false;
          const items = action.payload.sort(
            (a, b) => Number(a.sui_code) - Number(b.sui_code)
          );
          state.suiSocialStratum = items;
        }
      )
      .addCase(getSuiSocialStratums.rejected, (state, _action) => {
        state.isLoadingSuiSocialStratums = false;
      })
      // getSocialStratums
      .addCase(getSocialStratums.pending.type, (state, _action) => {
        state.isLoadingSocialStratums = true;
        state.paginationSocialStratum = undefined;
      })
      .addCase(
        getSocialStratums.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<SocialStratum>>) => {
          state.isLoadingSocialStratums = false;
          const items = action.payload.items.sort((a, b) =>
            a.code.localeCompare(b.code, "en", { numeric: true })
          );
          state.paginationSocialStratum = { ...action.payload, items: items };
        }
      )
      .addCase(getSocialStratums.rejected.type, (state, _action) => {
        state.isLoadingSocialStratums = false;
      })
      // getCatastralTypes
      .addCase(getCatastralTypes.pending.type, (state, _action) => {
        state.isLoadingCatastralTypes = true;
        state.catastralTypes = [];
      })
      .addCase(
        getCatastralTypes.fulfilled.type,
        (state, action: PayloadAction<CatrastalType[]>) => {
          state.isLoadingCatastralTypes = false;
          state.catastralTypes = action.payload;
        }
      )
      .addCase(getCatastralTypes.rejected.type, (state, _action) => {
        state.isLoadingCatastralTypes = false;
      })
      // getServiceProviders
      .addCase(getServiceProviders.pending.type, (state, _action) => {
        state.isLoadingProviders = true;
        state.paginationServiceProviders = undefined;
      })
      .addCase(
        getServiceProviders.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<ServiceProvider>>) => {
          state.isLoadingProviders = false;
          state.paginationServiceProviders = action.payload;
        }
      )
      .addCase(getServiceProviders.rejected.type, (state, _action) => {
        state.isLoadingProviders = false;
      })
      // getEnerbitElectricitySupplyServiceAgreements
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.pending.type,
        (state, _action) => {
          state.isLoadingEnerbitElectricitySupplyServiceAgreements = true;
          state.paginationEnerbitElectricitySupplyServiceAgreements = undefined;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.fulfilled.type,
        (
          state,
          action: PayloadAction<
            EnerbitPagination<EnerbitElectricitySupplyServiceAgreement>
          >
        ) => {
          state.isLoadingEnerbitElectricitySupplyServiceAgreements = false;
          state.paginationEnerbitElectricitySupplyServiceAgreements =
            action.payload;
        }
      )
      .addCase(
        getEnerbitElectricitySupplyServiceAgreements.rejected.type,
        (state, _action) => {
          state.isLoadingEnerbitElectricitySupplyServiceAgreements = false;
        }
      )
      // getElectricityMarketInfo
      .addCase(getElectricityMarketInfo.pending.type, (state, _action) => {
        state.isLoadingElectricityMarketInfo = true;
        state.paginationElectricityMarketInfo = undefined;
      })
      .addCase(
        getElectricityMarketInfo.fulfilled.type,
        (
          state,
          action: PayloadAction<EnerbitPagination<ElectricityMarketInfo>>
        ) => {
          state.isLoadingElectricityMarketInfo = false;
          state.paginationElectricityMarketInfo = action.payload;
        }
      )
      .addCase(getElectricityMarketInfo.rejected.type, (state, _action) => {
        state.isLoadingElectricityMarketInfo = false;
      })
      // getAssetOwnerShip
      .addCase(getAssetOwnership.pending.type, (state, _action) => {
        state.isLoadingAssetOwnership = true;
        state.paginationAssetOwnership = [];
      })
      .addCase(
        getAssetOwnership.fulfilled.type,
        (state, action: PayloadAction<AssetOwnerShip[]>) => {
          state.isLoadingAssetOwnership = false;
          state.paginationAssetOwnership = action.payload;
        }
      )
      .addCase(getAssetOwnership.rejected.type, (state, _action) => {
        state.isLoadingAssetOwnership = false;
      })
      // getTensionLevel
      .addCase(getTensionLevel.pending.type, (state, _action) => {
        state.isLoadingTensionLevel = true;
        state.paginationTensionLevel = [];
      })
      .addCase(
        getTensionLevel.fulfilled.type,
        (state, action: PayloadAction<TensionLevel[]>) => {
          state.isLoadingTensionLevel = false;
          state.paginationTensionLevel = action.payload;
        }
      )
      .addCase(getTensionLevel.rejected.type, (state, _action) => {
        state.isLoadingTensionLevel = false;
      })
      // getRetailers
      .addCase(getRetailers.pending.type, (state, _action) => {
        state.isLoadingRetailers = true;
        state.paginationRetailer = undefined;
      })
      .addCase(
        getRetailers.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Retailer>>) => {
          state.isLoadingRetailers = false;
          const items = action.payload.items.sort((a, b) => {
            if (a.description < b.description) {
              return -1;
            }
            if (a.description > b.description) {
              return 1;
            }
            return 0;
          });
          state.paginationRetailer = { ...action.payload, items };
        }
      )
      .addCase(getRetailers.rejected.type, (state, _action) => {
        state.isLoadingRetailers = false;
      })
      // getSubsistenceConsumptions
      .addCase(getSubsistenceConsumptions.pending.type, (state, _action) => {
        state.isLoadingSubsistenceConsumption = true;
        state.subsistenceConsumption = [];
      })
      .addCase(
        getSubsistenceConsumptions.fulfilled.type,
        (state, action: PayloadAction<SubsistenceConsumption[]>) => {
          state.isLoadingSubsistenceConsumption = false;
          state.subsistenceConsumption = action.payload;
        }
      )
      .addCase(getSubsistenceConsumptions.rejected.type, (state, _action) => {
        state.isLoadingSubsistenceConsumption = false;
      })
      // getPortafolioRiskUserTypes
      .addCase(getPortafolioRiskUserTypes.pending.type, (state, _action) => {
        state.isLoadingPortafolioRiskUserType = true;
        state.portafolioRiskUserTypes = [];
      })
      .addCase(
        getPortafolioRiskUserTypes.fulfilled.type,
        (state, action: PayloadAction<PortafolioRiskUserType[]>) => {
          state.isLoadingPortafolioRiskUserType = false;
          state.portafolioRiskUserTypes = action.payload;
        }
      )
      .addCase(getPortafolioRiskUserTypes.rejected.type, (state, _action) => {
        state.isLoadingPortafolioRiskUserType = false;
      })
      // getQualityGroups
      .addCase(getQualityGroups.pending.type, (state, _action) => {
        state.isLoadingQualityGroups = true;
        state.qualityGroups = [];
      })
      .addCase(
        getQualityGroups.fulfilled.type,
        (state, action: PayloadAction<QualityGroup[]>) => {
          state.isLoadingQualityGroups = false;
          const items = action.payload.sort((a, b) =>
            a.sui_code.localeCompare(b.sui_code, "en", { numeric: true })
          );
          state.qualityGroups = items;
        }
      )
      .addCase(getQualityGroups.rejected.type, (state, _action) => {
        state.isLoadingQualityGroups = false;
      })
      // getZonesSui
      .addCase(getZonesSui.pending.type, (state, _action) => {
        state.isLoadingZoneSui = true;
        state.zonesSui = [];
      })
      .addCase(
        getZonesSui.fulfilled.type,
        (state, action: PayloadAction<ZoneSui[]>) => {
          state.isLoadingZoneSui = false;
          state.zonesSui = action.payload;
        }
      )
      .addCase(getZonesSui.rejected.type, (state, _action) => {
        state.isLoadingZoneSui = false;
      })
      // getSpecialConditions
      .addCase(getSpecialConditions.pending.type, (state, _action) => {
        state.isLoadingSpecialConditions = true;
        state.specialConditions = [];
      })
      .addCase(
        getSpecialConditions.fulfilled.type,
        (state, action: PayloadAction<SpecialCondition[]>) => {
          state.isLoadingSpecialConditions = false;
          state.specialConditions = action.payload;
        }
      )
      .addCase(getSpecialConditions.rejected.type, (state, _action) => {
        state.isLoadingSpecialConditions = false;
      })
      // getSpecialAreaTypes
      .addCase(getSpecialAreaTypes.pending.type, (state, _action) => {
        state.isLoadingSpecialAreaTypes = true;
        state.specialConditions = [];
      })
      .addCase(
        getSpecialAreaTypes.fulfilled.type,
        (state, action: PayloadAction<SpecialAreaType[]>) => {
          state.isLoadingSpecialAreaTypes = false;
          state.specialAreaTypes = action.payload;
        }
      )
      .addCase(getSpecialAreaTypes.rejected.type, (state, _action) => {
        state.isLoadingSpecialAreaTypes = false;
      })
      // getLeads
      .addCase(getLeads.pending.type, (state, _action) => {
        state.isLoadingLeads = true;
        state.paginationLeads = undefined;
      })
      .addCase(
        getLeads.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Lead>>) => {
          state.isLoadingLeads = false;
          state.paginationLeads = action.payload;
        }
      )
      .addCase(getLeads.rejected.type, (state, _action) => {
        state.isLoadingLeads = false;
      })
      // getFrontierById
      .addCase(getFrontier.pending.type, (state, _action) => {
        state.isLoadingFrontier = true;
        state.frontier = [];
      })
      .addCase(
        getFrontier.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Frontier>>) => {
          state.isLoadingFrontier = false;
          state.frontier = action.payload.items;
        }
      )
      .addCase(getFrontier.rejected.type, (state, _action) => {
        state.isLoadingFrontier = false;
      })
      // getAutocompleteEssCreationInfo
      .addCase(
        getAutocompleteEssCreationInfo.pending.type,
        (state, _action) => {
          state.isLoadingAutocompleteInfo = true;
          state.autocompleteInfo = undefined;
        }
      )
      .addCase(
        getAutocompleteEssCreationInfo.fulfilled.type,
        (state, action: PayloadAction<AutoCompleteInfo>) => {
          state.isLoadingAutocompleteInfo = false;
          state.autocompleteInfo = action.payload;
        }
      )
      .addCase(
        getAutocompleteEssCreationInfo.rejected.type,
        (state, _action) => {
          state.isLoadingAutocompleteInfo = false;
        }
      )
      // getElectricitySupplyServiceAgreement
      .addCase(
        getElectricitySupplyServiceAgreement.pending.type,
        (state, _action) => {
          state.isLoadingElectricitySupplyServiceAgreement = true;
          state.paginationElectricitySupplyServiceAgreement = undefined;
        }
      )
      .addCase(
        getElectricitySupplyServiceAgreement.fulfilled.type,
        (
          state,
          action: PayloadAction<
            EnerbitPagination<ElectricitySupplyServiceAgreement>
          >
        ) => {
          state.isLoadingElectricitySupplyServiceAgreement = false;
          state.paginationElectricitySupplyServiceAgreement = action.payload;
        }
      )
      .addCase(
        getElectricitySupplyServiceAgreement.rejected.type,
        (state, _action) => {
          state.isLoadingElectricitySupplyServiceAgreement = false;
        }
      )
      // getMarkets
      .addCase(getMarkets.pending.type, (state, _action) => {
        state.isLoadingMarket = true;
        state.paginationMarkets = undefined;
      })
      .addCase(
        getMarkets.fulfilled.type,
        (state, action: PayloadAction<EnerbitPagination<Market>>) => {
          state.isLoadingMarket = false;
          const items = action.payload.items.sort((a, b) => {
            if (a.description < b.description) {
              return -1;
            }
            if (a.description > b.description) {
              return 1;
            }
            return 0;
          });
          state.paginationMarkets = { ...action.payload, items };
        }
      )
      .addCase(getMarkets.rejected.type, (state, _action) => {
        state.isLoadingMarket = false;
      })
      // getMarkets
      .addCase(getAutoGeneratorTypes.pending.type, (state, _action) => {
        state.isLoadingAutoGeneratorTypes = true;
        state.autogeneratorTypes = [];
      })
      .addCase(
        getAutoGeneratorTypes.fulfilled.type,
        (state, action: PayloadAction<AutoGeneratorType[]>) => {
          state.isLoadingAutoGeneratorTypes = false;
          const items = action.payload.sort((a, b) =>
            a.sui_code.localeCompare(b.sui_code, "en", { numeric: true })
          );
          state.autogeneratorTypes = items;
        }
      )
      .addCase(getAutoGeneratorTypes.rejected.type, (state, _action) => {
        state.isLoadingAutoGeneratorTypes = false;
      })
      // getExportEnergyGenerationTypes
      .addCase(
        getExportEnergyGenerationTypes.pending.type,
        (state, _action) => {
          state.isLoadingExportEnergyGenerationTypes = true;
          state.exportEnergyGenerationTypes = [];
        }
      )
      .addCase(
        getExportEnergyGenerationTypes.fulfilled.type,
        (state, action: PayloadAction<ExportEnergyGenerationType[]>) => {
          state.isLoadingExportEnergyGenerationTypes = false;
          const items = action.payload.sort((a, b) =>
            a.sui_code.localeCompare(b.sui_code, "en", { numeric: true })
          );
          state.exportEnergyGenerationTypes = items;
        }
      )
      .addCase(
        getExportEnergyGenerationTypes.rejected.type,
        (state, _action) => {
          state.isLoadingExportEnergyGenerationTypes = false;
        }
      )
      // getServiceStatus
      .addCase(getServiceStatus.pending.type, (state, _action) => {
        state.isLoadingServiceStatus = true;
        state.serviceStatus = [];
      })
      .addCase(
        getServiceStatus.fulfilled.type,
        (state, action: PayloadAction<ServiceStatus[]>) => {
          state.isLoadingServiceStatus = false;
          state.serviceStatus = action.payload;
        }
      )
      .addCase(getServiceStatus.rejected.type, (state, _action) => {
        state.isLoadingServiceStatus = false;
      })
      // createEstate
      .addCase(createEstate.pending.type, (state, _action) => {
        state.isCreatingEstate = true;
        state.serviceStatus = [];
      })
      .addCase(
        createEstate.fulfilled.type,
        (state, action: PayloadAction<Estate>) => {
          state.isCreatingEstate = false;
          state.estateCreated = action.payload;
        }
      )
      .addCase(createEstate.rejected.type, (state, _action) => {
        state.isCreatingEstate = false;
      })
      // createEstate
      .addCase(
        createElectricitySupplyService.pending.type,
        (state, _action) => {
          state.isCreatingElectricitySupplyService = true;
          state.serviceStatus = [];
        }
      )
      .addCase(
        createElectricitySupplyService.fulfilled.type,
        (state, action: PayloadAction<ElectricitySupplyService>) => {
          state.isCreatingElectricitySupplyService = false;
          state.electricitySupplyServiceCreated = action.payload;
        }
      )
      .addCase(
        createElectricitySupplyService.rejected.type,
        (state, _action) => {
          state.isCreatingElectricitySupplyService = false;
        }
      )
      // getExportEnergy
      .addCase(getExportEnergy.pending.type, (state, _action) => {
        state.isLoadingExportEnergy = true;
        state.exportEnergy = [];
      })
      .addCase(
        getExportEnergy.fulfilled.type,
        (state, action: PayloadAction<ExportEnergy[]>) => {
          state.isLoadingExportEnergy = false;
          state.exportEnergy = action.payload;
        }
      )
      .addCase(getExportEnergy.rejected.type, (state, _action) => {
        state.isLoadingExportEnergy = false;
      })
      // createEnerbitElectricitySupplyService
      .addCase(
        createEnerbitElectricitySupplyService.pending.type,
        (state, _action) => {
          state.isCreatingEnerbitElectricitySupply = true;
          state.exportEnergy = [];
        }
      )
      .addCase(
        createEnerbitElectricitySupplyService.fulfilled.type,
        (state, action: PayloadAction<any>) => {
          state.isCreatingEnerbitElectricitySupply = false;
          if (action.payload) {
            state.wasSuccesfullyCreated = true;
          }
        }
      )
      .addCase(
        createEnerbitElectricitySupplyService.rejected.type,
        (state, _action) => {
          state.isCreatingEnerbitElectricitySupply = false;
        }
      )
      // updateCregSuscribers
      .addCase(updateElectricitySupply.pending.type, (state, _action) => {
        state.isUpdateCregSuscribers = true;
        state.wasUpdateCregSuscribers = null;
      })
      .addCase(
        updateElectricitySupply.fulfilled.type,
        (state, action: PayloadAction<any>) => {
          state.isUpdateCregSuscribers = false;
          state.wasUpdateCregSuscribers = true;
        }
      )
      .addCase(updateElectricitySupply.rejected.type, (state, _action) => {
        state.isUpdateCregSuscribers = false;
        state.wasUpdateCregSuscribers = false;
      });
  },
});

export const {
  onNextPageRequested,
  onPreviousPageRequested,
  onCleanForm,
  onNavigateToSpecificPage,
  onContractInformationAutoCompleteChanged,
  onEstateInformationAutoCompleteChanged,
  onRegulatoryInformationAutoCompleteChanged,
  onTechnicalInformationAutoCompleteChanged,
  setCleanVars,
  cleanStoreCreateService,
} = createServiceSlice.actions;

export default createServiceSlice.reducer;
