import {
  Box,
  Button,
  CircularProgress,
  CustomAlert,
  EditOutlinedIcon,
  FormikProvider,
  Grid,
  InitialHourCompensationsArray,
  InitialTimesCompensationsArray,
  LoadingButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useFormik,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fillFieldsHistory,
  getInitialCompesatoryHistoricValues,
  shouldShowDependentAutoGeneratorTypeFields,
  shouldShowDependentExportEnergyFields,
} from "../common/FormUtils";
import { FormikType, handleTextFieldChanged } from "../common/Utils";
import { ElectricitySupplyCreate } from "../models/electricity-supply-create/electricity-supply-create";
import { CreateServiceFormType } from "../models/form/CreateServiceTypeForm";
import { validationSchema } from "../models/form/ValidationSchemas";
import { PropsApp } from "../models/props/PropsApp";
import { StoreState } from "../models/states/StoreState";
import {
  createEstate,
  getServiceProviders,
} from "../store/actions/accounts/accounts.actions";
import {
  createElectricitySupplyService,
  createEnerbitElectricitySupplyService,
  updateElectricitySupply,
} from "../store/actions/electricity-supply-service/electricity-supply-service.actions";
import { getFrontier } from "../store/actions/field-service-certifications/field-service-certifications.actions";
import { getLeads } from "../store/actions/leads/leads.actions";
import {
  cleanStoreCreateService,
  onCleanForm,
  onNavigateToSpecificPage,
  onNextPageRequested,
  onPreviousPageRequested,
  setCleanVars,
} from "../store/slices/createServiceSlice";
import { AppDispatch } from "../store/store";
import { Bombi } from "./components/Bombi";
import { ModalEnerbitESS } from "./components/ModalEnerbitESS";
import ContractInformationForm from "./contract-information/ContractInformation";
import EstateInformationForm from "./estate-information/EstateInformation";
import { EstateSelection } from "./estate-selection/EstateSelection";
import IdsInformation from "./ids-information.tsx/IdsInformation";
import RegulatoryInformationForm from "./regulatory-information/RegulatoryInformation";
import TechnicalInformationForm from "./technical-information/TechnicalInformation";

export const StepperComponent = ({
  leadUserEmail,
  serviceAccountId,
  userId,
  onCloseModal,
  service,
  estate,
  frontiers,
}: PropsApp) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentStep,
    isLoadingLeads,
    paginationLeads,
    isLoadingFrontier,
    frontier,
    paginationServiceProviders,
    specialAreaTypes,
    estateCreated,
    paginationDataCities,
    paginationSocialStratum,
    paginationDataStates,
    paginationDataCountries,
    paginationMarkets,
    paginationRetailer,
    isCreatingElectricitySupplyService,
    isCreatingEstate,
    electricitySupplyServiceCreated,
    isCreatingEnerbitElectricitySupply,
    isLoadingAutocompleteInfo,
    wasSuccesfullyCreated,
    autogeneratorTypes,
    paginationEnerbitElectricitySupplyServiceAgreements,
    specialConditions,
    exportEnergy,
    exportEnergyGenerationTypes,
    wasUpdateCregSuscribers,
    isUpdateCregSuscribers,
  } = useSelector((state: StoreState) => state.createServiceForm);

  useEffect(() => {
    return () => {
      dispatch(cleanStoreCreateService());
    };
  }, [dispatch]);

  const formik: FormikType<CreateServiceFormType> =
    useFormik<CreateServiceFormType>({
      initialValues: {
        estate: "",
        cuttable: true,
        serviceStatusId: "",
        selectedCountryId: "",
        selectedStateId: "",
        selectedCityId: "",
        address: "",
        socialStratumId: "",
        catastralTypeId: "",
        catastralIdentifier: "",
        exportEnergyGenerationTypeId: "",
        longitude: 0,
        latitude: 0,
        altitude: 0,
        plusCode: "",
        serviceProviderId: "",
        electricitySupplyServiceContractType: "",
        willHaveEnerbitServices: true,
        dateStartEnerbitService: null,
        installedCapacity: "0",
        niu: "",
        transformerCode: "",
        averageUsage: 0,
        tensionLevelId: "",
        codeNetworkOperator: "",
        reactiveCounterM: 1,
        numberOfPeriodsWithoutPenalty: 0,
        reactiveCounterM1: 0,
        reactiveCounterM6: 0,
        marketerId: "",
        frontierId: "",
        frontierRegisterDate: null,
        daneCode: "",
        oldMarketerId: "",
        assetOwnershipId: "",
        marketingMarketId: "",
        subsistenceConsumptionId: "",
        portafolioRiskUserTypeId: "",
        qualityGroupId: "",
        zoneSuiId: "",
        specialConditionId: "",
        suiSocialStratumId: "",
        specialAreaTypeId: "",
        circuitCode: "",
        contract_file: null,
        hasCompensationHistory: false,
        hasBeenRegisterOnXm: true,
        autocompleteInformation: true,
        compensationHistory: getInitialCompesatoryHistoricValues(),
        autogeneratorTypeId: "",
        measurementPointId: "",
      },
      validationSchema: validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {},
    });

  const steps: StepData[] = [
    {
      label: "Selección de la oportunidad",
      component: (
        <EstateSelection
          index={1}
          formik={formik}
          disabled={service == null ? false : true}
        />
      ),
    },
    {
      label: "Información de la oportunidad",
      component: (
        <EstateInformationForm
          index={2}
          formik={formik}
          disabled={service == null ? false : true}
        />
      ),
    },
    {
      label: "Información de contrato",
      component: (
        <ContractInformationForm
          index={3}
          formik={formik}
          disabled={service == null ? false : true}
        />
      ),
    },
    {
      label: "Información técnica",
      component: (
        <TechnicalInformationForm
          index={4}
          formik={formik}
          disabled={service == null ? false : true}
        />
      ),
    },
    {
      label: "Información regulatoria",
      component: (
        <RegulatoryInformationForm
          service={service}
          index={5}
          formik={formik}
          frontiers={frontiers?.items}
          disabled={service == null ? false : true}
        />
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const isValidEstateInformationStep = (): boolean => {
    if (
      formik.errors.estate ||
      formik.errors.selectedCountryId ||
      formik.errors.selectedStateId ||
      formik.errors.selectedCityId ||
      formik.errors.address ||
      formik.errors.socialStratumId ||
      formik.errors.catastralTypeId ||
      formik.errors.catastralIdentifier ||
      formik.errors.longitude ||
      formik.errors.latitude ||
      formik.errors.altitude ||
      formik.errors.plusCode
    ) {
      formik.setFieldTouched("estate");
      formik.setFieldTouched("selectedCountryId");
      formik.setFieldTouched("selectedStateId");
      formik.setFieldTouched("selectedCityId");
      formik.setFieldTouched("address");
      formik.setFieldTouched("socialStratumId");
      formik.setFieldTouched("catastralTypeId");
      formik.setFieldTouched("catastralIdentifier");
      formik.setFieldTouched("longitude");
      formik.setFieldTouched("latitude");
      formik.setFieldTouched("altitude");
      formik.setFieldTouched("plusCode");
      return false;
    }
    return true;
  };

  const isValidContractInformationStep = (): boolean => {
    if (
      formik.errors.serviceProviderId ||
      formik.errors.electricitySupplyServiceContractType ||
      formik.errors.willHaveEnerbitServices ||
      formik.errors.dateStartEnerbitService ||
      formik.errors.enerbitServicePlanId
    ) {
      formik.setFieldTouched("serviceProviderId");
      formik.setFieldTouched("electricitySupplyServiceContractType");
      formik.setFieldTouched("willHaveEnerbitServices");
      formik.setFieldTouched("dateStartEnerbitService");
      formik.setFieldTouched("enerbitServicePlanId");
      return false;
    }
    return true;
  };

  const isValidTechnicalInformationStep = (): boolean => {
    if (
      formik.errors.installedCapacity ||
      formik.errors.niu ||
      formik.errors.circuitCode ||
      formik.errors.transformerCode ||
      formik.errors.averageUsage ||
      formik.errors.codeNetworkOperator ||
      formik.errors.tensionLevelId ||
      formik.errors.reactiveCounterM ||
      formik.errors.reactiveCounterM1 ||
      formik.errors.reactiveCounterM6 ||
      formik.errors.numberOfPeriodsWithoutPenalty ||
      formik.errors.hasCompensationHistory ||
      formik.errors.compensationHistory
    ) {
      formik.setFieldTouched("installedCapacity");
      formik.setFieldTouched("niu");
      formik.setFieldTouched("circuitCode");
      formik.setFieldTouched("transformerCode");
      formik.setFieldTouched("averageUsage");
      formik.setFieldTouched("codeNetworkOperator");
      formik.setFieldTouched("tensionLevelId");
      formik.setFieldTouched("reactiveCounterM");
      formik.setFieldTouched("reactiveCounterM1");
      formik.setFieldTouched("reactiveCounterM6");
      formik.setFieldTouched("numberOfPeriodsWithoutPenalty");
      formik.setFieldTouched("hasCompensationHistory");
      formik.setFieldTouched("compensationHistory");
      return false;
    }
    return true;
  };

  const isValidRegulatoryInformationStep = (): boolean => {
    if (
      formik.errors.marketerId ||
      formik.errors.marketingMarketId ||
      formik.errors.frontierId ||
      formik.errors.frontierRegisterDate ||
      formik.errors.daneCode ||
      formik.errors.oldMarketerId ||
      formik.errors.assetOwnershipId ||
      formik.errors.qualityGroupId ||
      formik.errors.zoneSuiId ||
      formik.errors.specialConditionId ||
      formik.errors.specialAreaTypeId ||
      formik.errors.suiSocialStratumId ||
      formik.errors.portafolioRiskUserTypeId ||
      formik.errors.subsistenceConsumptionId ||
      formik.errors.autogeneratorTypeId ||
      formik.errors.frontierXmAutogeneratorCode ||
      formik.errors.backUpAgreement ||
      formik.errors.backUpAgreementPowerCapacity ||
      formik.errors.exportEnergyId ||
      formik.errors.exportEnergyPowerCapacity ||
      formik.errors.exportEnergyGenerationTypeId ||
      formik.errors.exportEnergyStartDatetime
    ) {
      formik.setFieldTouched("marketerId");
      formik.setFieldTouched("marketingMarketId");
      formik.setFieldTouched("frontierId");
      formik.setFieldTouched("frontierRegisterDate");
      formik.setFieldTouched("daneCode");
      formik.setFieldTouched("oldMarketerId");
      formik.setFieldTouched("assetOwnershipId");
      formik.setFieldTouched("qualityGroupId");
      formik.setFieldTouched("zoneSuiId");
      formik.setFieldTouched("specialConditionId");
      formik.setFieldTouched("specialAreaTypeId");
      formik.setFieldTouched("suiSocialStratumId");
      formik.setFieldTouched("portafolioRiskUserTypeId");
      formik.setFieldTouched("subsistenceConsumptionId");
      formik.setFieldTouched("autogeneratorTypeId");
      formik.setFieldTouched("frontierXmAutogeneratorCode");
      formik.setFieldTouched("backUpAgreement");
      formik.setFieldTouched("backUpAgreementPowerCapacity");
      formik.setFieldTouched("exportEnergyId");
      formik.setFieldTouched("exportEnergyPowerCapacity");
      formik.setFieldTouched("exportEnergyGenerationTypeId");
      formik.setFieldTouched("exportEnergyStartDatetime");
      return false;
    }
    return true;
  };

  const handleOnNextRequested = (
    _event?: React.MouseEvent<HTMLElement>
  ): void => {
    if (currentStep < steps.length - 1) {
      if (currentStep == 1 && !isValidEstateInformationStep()) {
        return;
      }
      if (currentStep == 2 && !isValidContractInformationStep()) {
        return;
      }
      if (currentStep == 3 && !isValidTechnicalInformationStep()) {
        return;
      }
      if (currentStep == 4 && !isValidRegulatoryInformationStep()) {
        return;
      }

      const contentStepper = document.getElementById("modal-dialog-content");
      if (contentStepper) {
        const parent = contentStepper.parentElement?.parentElement;
        if (parent) {
          parent.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
      if (currentStep == 2 && !formik.values.willHaveEnerbitServices) {
        setOpen(true);
        return;
      }
      dispatch(onNextPageRequested());
    }
    if (currentStep !== 4) {
      return;
    }
    if (!isValidRegulatoryInformationStep()) {
      return;
    }

    const estate = getEstateFields();

    if (estate != null) {
      dispatch(createEstate(estate));
    }
  };

  const handleOnPreviousRequested = (
    _event: React.MouseEvent<HTMLElement>
  ): void => {
    if (currentStep > 0) {
      dispatch(onPreviousPageRequested());
    }
  };

  const getEstateFields = () => {
    let estate = null;
    const country = paginationDataCountries?.items.find(
      (value) => value.id == formik.values.selectedCountryId
    );
    const state = paginationDataStates?.items.find(
      (value) => value.id == formik.values.selectedStateId
    );
    const city = paginationDataCities?.items.find(
      (value) => value.id == formik.values.selectedCityId
    );
    if (country && state && city) {
      estate = {
        address: formik.values.address,
        city: city.name,
        state: state.name,
        country: country.name,
        dane_code: formik.values.daneCode,
        geolocalization: {
          altitude: formik.values.altitude,
          latitude: formik.values.latitude,
          longitude: formik.values.longitude,
          plus_code: formik.values.plusCode,
        },
        timezone: "America/Bogota",
      };
    }
    return estate;
  };

  const getCregSubscriberFields = (autogeneratorTypeData?: any) => {
    let cregSubscriber: ElectricitySupplyCreate = {
      create_creg_subscriber: {
        circuit_code: formik.values?.circuitCode,
        dane_code: formik.values.daneCode,
        electric_transformer_code: formik.values.transformerCode,
        //frontier_id: formik.values.frontierId,
        frontier_xm_code: formik.values.frontierId,
        initial_average_active_energy_usage: formik.values.averageUsage,
        initial_hour_compensations_array: formik.values.compensationHistory.map(
          (compensationHistory) => {
            return {
              month: compensationHistory.month,
              number_of_hours: Number(compensationHistory.hour),
            } as InitialHourCompensationsArray;
          }
        ),
        initial_times_compensations_array:
          formik.values.compensationHistory.map((compensationHistory) => {
            return {
              month: compensationHistory.month,
              number_of_times: Number(compensationHistory.hour),
            } as InitialTimesCompensationsArray;
          }),
        distribution_operator_subscriber_id: formik.values.codeNetworkOperator,
        // validar que sean menor o igual a 12
        initial_reactive_m_counter: formik.values.reactiveCounterM,
        // validar que sean menor o igual a 12
        initial_reactive_m1_counter: formik.values.reactiveCounterM1,
        // validar que sean menor o igual a 12
        initial_reactive_m2_counter: formik.values.reactiveCounterM6,
        // validar que sean menor o igual a 3
        initial_reactive_wop_counter:
          formik.values.numberOfPeriodsWithoutPenalty,
        market_sui_code:
          paginationMarkets?.items.find(
            (value) => value.id == formik.values.marketingMarketId
          )?.sui_code ?? "",
        niu: formik.values.niu,
        portafolio_risk_user_type_id: formik.values.portafolioRiskUserTypeId,
        prev_retail_market_sui_code:
          paginationRetailer?.items.find(
            (value) => value.id == formik.values.oldMarketerId
          )?.sui_code ?? "",
        quality_group_id: formik.values?.qualityGroupId ?? "",
        retail_market_sui_code:
          paginationRetailer?.items.find(
            (value) => value.id == formik.values.marketerId
          )?.sui_code ?? "",
        special_area_code:
          specialAreaTypes.find(
            (value) => value.id == formik.values.specialAreaTypeId
          )?.sui_code ?? "",
        special_area_type_id: formik.values.specialAreaTypeId,
        special_condition_id: formik.values.specialConditionId,
        subsistence_consumption_id: formik.values.subsistenceConsumptionId,
        sui_social_stratum_id: formik.values.suiSocialStratumId,
        voltage_level_id: formik.values.tensionLevelId,
        zone_id:
          formik.values?.zoneSuiId !== null ? formik.values.zoneSuiId : "",
        autogenerator_type_id:
          autogeneratorTypes.find(
            (value) => value.sui_code == formik.values.autogeneratorTypeId
          )?.id ?? "",
        ...autogeneratorTypeData,
      },
      cuttable: formik.values.cuttable,
      power_capacity_contracted: Number(formik.values.installedCapacity),
      service_account_id: serviceAccountId,
      service_agreement_id: formik.values.electricitySupplyServiceContractType,
      service_agreement_path_file: "agreements/20210512_kkdfs_123_daad.pdf",
      service_provider_id: formik.values.serviceProviderId,
      service_status_id: formik.values.serviceStatusId,
      settlement_schedule_crontab_utc: "0 15 16 * *",
      started_at: moment(formik.values.frontierRegisterDate)?.toDate(),
      catastral_id: formik.values.catastralIdentifier,
      catastral_type_id: formik.values.catastralTypeId,
      social_stratum_id: formik.values.socialStratumId,
      estate_id: estateCreated?.id ?? service?.estate.id,
      measurement_point_id: formik.values.measurementPointId || "",
      ended_at: null,
    };

    if (!formik.values?.qualityGroupId) {
      delete cregSubscriber.create_creg_subscriber.quality_group_id;
    } else {
      cregSubscriber.create_creg_subscriber.quality_group_id =
        formik.values.qualityGroupId;
    }

    // Eliminar `asset_ownership_id` si no está presente
    if (!formik.values?.assetOwnershipId) {
      delete cregSubscriber.create_creg_subscriber.asset_ownership_id;
    } else {
      cregSubscriber.create_creg_subscriber.asset_ownership_id =
        formik.values.assetOwnershipId;
    }
    return cregSubscriber;
  };

  // Use effects
  useEffect(() => {
    if (estateCreated) {
      let autogeneratorTypeData = {};
      if (
        shouldShowDependentAutoGeneratorTypeFields(
          autogeneratorTypes,
          formik.values.autogeneratorTypeId
        )
      ) {
        autogeneratorTypeData = {
          frontier_xm_autogenerator_code:
            formik.values.frontierXmAutogeneratorCode,
          back_up_agreement: formik.values.backUpAgreement,
          back_up_agreement_power_capacity:
            formik.values.backUpAgreementPowerCapacity,
          export_energy_id: formik.values.exportEnergyId,
        };
        if (shouldShowDependentExportEnergyFields(exportEnergy, formik)) {
          autogeneratorTypeData = {
            ...autogeneratorTypeData,
            export_energy_power_capacity:
              formik.values.exportEnergyPowerCapacity,
            export_energy_start_datetime:
              formik.values.exportEnergyStartDatetime?.toDate(),
            export_energy_generation_type_id:
              formik.values.exportEnergyGenerationTypeId,
          };
          //ToDo
        } else {
          autogeneratorTypeData = {
            ...autogeneratorTypeData,
            export_energy_power_capacity: null,
            export_energy_start_datetime: null,
            export_energy_generation_type_id: null,
          };
        }
        //ToDo
      } else {
        autogeneratorTypeData = {
          frontier_xm_autogenerator_code: null,
          back_up_agreement: null,
          back_up_agreement_power_capacity: null,
          export_energy_id: null,
          export_energy_power_capacity: null,
          export_energy_start_datetime: null,
          export_energy_generation_type_id: null,
        };
      }
      const electricitySupplyServiceCreate: ElectricitySupplyCreate =
        getCregSubscriberFields(autogeneratorTypeData);

      dispatch(createElectricitySupplyService(electricitySupplyServiceCreate));
    }
  }, [estateCreated]);

  useEffect(() => {
    if (service == null) {
      dispatch(getLeads(leadUserEmail));
    }
  }, []);

  // Leads
  useEffect(() => {
    if (paginationLeads && paginationLeads?.items?.length > 0) {
      handleTextFieldChanged(paginationLeads.items[0].id, "estate", formik);
      handleTextFieldChanged(
        paginationLeads.items[0].measurement_point_id,
        "measurementPointId",
        formik
      );
    }
  }, [paginationLeads]);

  useEffect(() => {
    if (
      !isLoadingLeads &&
      paginationLeads &&
      paginationLeads.items.length > 0
    ) {
      const leadSelected = paginationLeads.items.find(
        (lead) => lead.id === formik.values.estate
      );
      handleTextFieldChanged(
        leadSelected?.measurement_point_id,
        "measurementPointId",
        formik
      );
      if (leadSelected) {
        dispatch(
          getFrontier({
            lead_id: leadSelected.id,
          })
        );
      }
    }
  }, [formik.values.estate]);

  // Autocomplete info
  useEffect(() => {
    if (!isLoadingFrontier && frontier) {
      handleTextFieldChanged(
        frontier[0]?.frontier_xm_code ?? "",
        "frontierId",
        formik
      );
    }
  }, [frontier]);

  // Providers
  useEffect(() => {
    if (
      !paginationServiceProviders ||
      paginationServiceProviders.items.length == 0
    ) {
      dispatch(getServiceProviders());
    }
  }, []);

  useEffect(() => {
    if (
      electricitySupplyServiceCreated &&
      formik.values.enerbitServicePlanId &&
      formik.values.willHaveEnerbitServices
    ) {
      dispatch(
        createEnerbitElectricitySupplyService({
          cuttable: formik.values.cuttable,
          electricity_supply_service_id: electricitySupplyServiceCreated.id,
          ended_at: electricitySupplyServiceCreated.ended_at,
          service_agreement_id: formik.values.enerbitServicePlanId,
          service_status_id: formik.values.serviceStatusId,
          started_at: formik.values.dateStartEnerbitService!.toDate(),
          user_id: userId,
        })
      );
    } else if (
      electricitySupplyServiceCreated &&
      !formik.values.willHaveEnerbitServices
    ) {
      onCloseModal(electricitySupplyServiceCreated);
      formik.resetForm();
      dispatch(onCleanForm());
    }
  }, [electricitySupplyServiceCreated]);

  useEffect(() => {
    if (wasSuccesfullyCreated && electricitySupplyServiceCreated) {
      onCloseModal(electricitySupplyServiceCreated);
      formik.resetForm();
      dispatch(onCleanForm());
    }
  }, [wasSuccesfullyCreated]);

  useEffect(() => {
    if (service != null) {
      formik.values.address = service.estate.address;
      formik.values.socialStratumId =
        service.creg_subscriber.sui_social_stratum.id;
      formik.values.latitude = service.estate.geolocalization.latitude;
      formik.values.longitude = service.estate.geolocalization.longitude;
      formik.values.altitude = service.estate.geolocalization.altitude;
      formik.values.plusCode = service.estate.geolocalization.plus_code;
      formik.values.serviceProviderId = service.service_provider_id;
      formik.values.electricitySupplyServiceContractType =
        service.service_agreement.id;
      formik.values.cuttable =
        service.enerbit_electricity_supply_service?.cuttable || false;
      formik.values.serviceStatusId = service.service_status_id;

      formik.values.installedCapacity = String(
        service.power_capacity_contracted
      );
      formik.values.niu = service.creg_subscriber.niu;
      formik.values.circuitCode = service.creg_subscriber.circuit_code || "";
      formik.values.transformerCode =
        service.creg_subscriber.electric_transformer_code || "";
      formik.values.averageUsage =
        service.creg_subscriber.initial_average_active_energy_usage;
      formik.values.codeNetworkOperator =
        service.creg_subscriber.distribution_operator_subscriber_id || "";
      formik.values.tensionLevelId = service.creg_subscriber.voltage_level.id;
      formik.values.reactiveCounterM = Number(
        service.creg_subscriber.initial_reactive_m_counter
      );
      formik.values.reactiveCounterM1 = Number(
        service.creg_subscriber.initial_reactive_m1_counter
      );
      formik.values.reactiveCounterM6 = Number(
        service.creg_subscriber.initial_reactive_m2_counter
      );
      formik.values.numberOfPeriodsWithoutPenalty = Number(
        service.creg_subscriber.initial_reactive_wop_counter
      );
      formik.values.frontierXmAutogeneratorCode =
        service.creg_subscriber.frontier_xm_autogenerator_code;
      //ToDo
      if (frontiers != null && frontiers?.items.length > 0) {
        formik.values.frontierRegisterDate = moment(
          frontiers.items[0].frontier_xm_registered_from
        );
        formik.values.frontierId = frontiers.items[0]?.frontier_xm_code;
      } else {
        formik.values.frontierRegisterDate = null;
        formik.values.frontierId = "NO ES FRONTERA";
      }
      formik.values.daneCode = service.creg_subscriber.dane_code;
      formik.values.assetOwnershipId =
        service.creg_subscriber?.asset_ownership?.id;
      if (
        service.creg_subscriber.quality_group &&
        service.creg_subscriber.quality_group.id
      ) {
        formik.values.qualityGroupId = service.creg_subscriber.quality_group.id;
      }
      formik.values.zoneSuiId = service.creg_subscriber?.zone?.id;
      formik.values.specialAreaTypeId =
        service.creg_subscriber.special_area_type.id;
      formik.values.suiSocialStratumId =
        service.creg_subscriber.sui_social_stratum.id;
      formik.values.portafolioRiskUserTypeId =
        service.creg_subscriber.portafolio_risk_user_type.id;
      formik.values.subsistenceConsumptionId =
        service.creg_subscriber.subsistence_consumption.id;

      if (
        service.enerbit_electricity_supply_service?.enerbit_service_agreement.id
      ) {
        formik.values.dateStartEnerbitService = moment(
          service.enerbit_electricity_supply_service?.started_at
        );
      } else {
        formik.values.willHaveEnerbitServices = false;
      }

      formik.values.catastralIdentifier = estate?.details.catastral_id || "";
      formik.values.catastralTypeId = estate?.details.catastral_type.id || "";
      formik.values.hasCompensationHistory = true;

      if (
        service?.creg_subscriber.initial_hour_compensations_array &&
        service?.creg_subscriber.initial_times_compensations_array
      ) {
        if (
          service.creg_subscriber.initial_hour_compensations_array.length ==
          service.creg_subscriber.initial_times_compensations_array.length
        ) {
          formik.values.compensationHistory = fillFieldsHistory(
            service.creg_subscriber.initial_hour_compensations_array,
            service.creg_subscriber.initial_times_compensations_array
          );
        }
      }
    } else {
      formik.values.marketingMarketId = " ";
      formik.values.oldMarketerId = " ";
      formik.values.marketerId = " ";
    }
  }, [service]);

  useEffect(() => {
    if (service != null) {
      formik.values.specialConditionId =
        service?.creg_subscriber.special_condition.id || "";
    }
  }, [specialConditions]);

  useEffect(() => {
    if (service != null) {
      formik.values.autogeneratorTypeId =
        service?.creg_subscriber.autogenerator_type.sui_code || "";
      formik.values.backUpAgreement = Boolean(
        service?.creg_subscriber.back_up_agreement
      );
      formik.values.backUpAgreementPowerCapacity =
        service?.creg_subscriber.back_up_agreement_power_capacity || 0;
    }
  }, [autogeneratorTypes]);

  useEffect(() => {
    if (service != null) {
      formik.values.enerbitServicePlanId =
        service?.enerbit_electricity_supply_service?.enerbit_service_agreement.id;
    }
  }, [paginationEnerbitElectricitySupplyServiceAgreements]);

  useEffect(() => {
    if (service != null) {
      const marketingMarketId =
        paginationMarkets?.items.find(
          (value) => value.sui_code == service?.creg_subscriber.market_sui_code
        )?.id ?? "";

      formik.values.marketingMarketId = marketingMarketId;
    }
  }, [paginationMarkets]);

  useEffect(() => {
    if (service != null) {
      const oldMarketSuiCode =
        paginationRetailer?.items.find(
          (value) =>
            value.sui_code ==
            service?.creg_subscriber.prev_retail_market_sui_code
        )?.id ?? "";

      formik.values.oldMarketerId = oldMarketSuiCode;

      const id =
        paginationRetailer?.items.find(
          (value) =>
            value.sui_code == service?.creg_subscriber.retail_market_sui_code
        )?.id || "";
      formik.values.marketerId = id;
    }
  }, [paginationRetailer]);

  useEffect(() => {
    if (service != null) {
      formik.values.socialStratumId =
        service?.estate.details.social_stratum.id || "";
    }
  }, [paginationSocialStratum]);

  useEffect(() => {
    if (service != null) {
      const country = paginationDataCountries?.items.find(
        (e) => e.name.toLowerCase() == service?.estate.country.toLowerCase()
      );
      formik.values.selectedCountryId = country?.id || "";
    }
  }, [paginationDataCountries]);

  useEffect(() => {
    if (
      service != null &&
      paginationDataStates &&
      paginationDataStates.items.length > 0
    ) {
      const depto = paginationDataStates?.items.find(
        (e) => e.name.toLowerCase() == service?.estate.state.toLowerCase()
      );
      formik.setFieldValue("selectedStateId", depto?.id);
    }
  }, [paginationDataStates]);

  useEffect(() => {
    if (
      service != null &&
      paginationDataCities &&
      paginationDataCities.items.length > 0
    ) {
      const city = paginationDataCities?.items.find(
        (e) => e.name.toLowerCase() == service?.estate.city.toLowerCase()
      );
      formik.setFieldValue("selectedCityId", city?.id);
    }
  }, [paginationDataCities]);

  useEffect(() => {
    if (service != null) {
      formik.values.exportEnergyPowerCapacity =
        service?.creg_subscriber.export_energy_power_capacity || 0;
      formik.values.exportEnergyStartDatetime = moment(
        service?.creg_subscriber.export_energy_start_datetime
      );
      formik.values.exportEnergyGenerationTypeId =
        service?.creg_subscriber.export_energy_generation_type?.id;
      formik.values.exportEnergyId = service?.creg_subscriber.export_energy?.id;
    }
  }, [exportEnergyGenerationTypes, exportEnergy]);

  useEffect(() => {
    formik.resetForm();
    dispatch(onCleanForm());
  }, []);

  if (isLoadingLeads) {
    return (
      <Box className="center-items box-loading">
        <CircularProgress />
      </Box>
    );
  }

  if ((!paginationLeads || paginationLeads?.items?.length === 0) && !service) {
    return (
      <Bombi
        message="Actualmente no cuentas con ninguna oportunidad para crear un servicio de
    energía"
      />
    );
  }

  const handleButtonClick = () => {
    const estate = getEstateFields();
    formik.submitForm().then(() => {
      if (formik.isValid) {
        if (estate != null && service != null) {
          dispatch(
            updateElectricitySupply({
              creg_subscriber:
                getCregSubscriberFields()?.create_creg_subscriber,
              estate: estate,
              id_estate: service.estate.id,
              id: service.creg_subscriber.id,
            })
          );
        }
      }
    });
  };

  return (
    <>
      {!service ? (
        <Box sx={{ width: "100%" }} mt={"51px"} mb={"46px"}>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((step, index) => (
              <Step
                sx={{
                  cursor: index < currentStep ? "pointer" : "default",
                }}
                onClick={(_) => {
                  if (index < currentStep) {
                    dispatch(onNavigateToSpecificPage(index));
                  }
                }}
                key={step.label}
              >
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box
            sx={{
              margin: "60px 44px 0px 44px",
            }}
          >
            <form>
              {steps[currentStep].component}
              {(formik.values.measurementPointId === "" ||
                formik.values.measurementPointId === null ||
                frontier.length === 0) && (
                <Box sx={{ mt: "2rem" }}>
                  <CustomAlert
                    text={
                      frontier.length === 0
                        ? "No es posible crear este servicio debido a que no se le ha asociado ninguna frontera."
                        : "Este servicio no puede crearse todavía ya que no cuenta con información de punto de medida. Valida que el proceso ante XM esté completo y que el acta de instalación ya exista."
                    }
                    severity={"error"}
                    onClose={function (): void {
                      throw new Error("Function not implemented.");
                    }}
                  ></CustomAlert>
                </Box>
              )}
              <Grid container spacing={4} marginTop={"2rem"}>
                {currentStep != 0 && (
                  <Grid item xs style={{ paddingTop: "0px" }}>
                    <Button
                      style={{ width: "100%" }}
                      variant="outlined"
                      color="secondary"
                      onClick={handleOnPreviousRequested}
                    >
                      Atrás
                    </Button>
                  </Grid>
                )}

                <Grid item xs style={{ paddingTop: "0px" }}>
                  <LoadingButton
                    className="Loading-button"
                    style={{ width: "100%" }}
                    variant="contained"
                    color="secondary"
                    onClick={handleOnNextRequested}
                    loading={
                      isCreatingElectricitySupplyService ||
                      isCreatingEstate ||
                      isCreatingEnerbitElectricitySupply
                    }
                    disabled={
                      isCreatingElectricitySupplyService ||
                      isCreatingEstate ||
                      isCreatingEnerbitElectricitySupply ||
                      formik.values.measurementPointId === "" ||
                      formik.values.measurementPointId === null ||
                      frontier.length === 0
                    }
                  >
                    {currentStep < steps.length - 1
                      ? "Siguiente"
                      : isCreatingElectricitySupplyService
                      ? "Creando servicio..."
                      : isCreatingEstate
                      ? "Creando estate..."
                      : "Guardar"}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      ) : (
        <>
          <FormikProvider value={formik}>
            {wasUpdateCregSuscribers != null && (
              <CustomAlert
                severity={wasUpdateCregSuscribers ? "success" : "error"}
                text={
                  wasUpdateCregSuscribers
                    ? "Servicio actualizado exitosamente"
                    : "Ocurrió un error actualizando el servicio"
                }
                onClose={() => dispatch(setCleanVars())}
                sx={{ mb: "24px" }}
              />
            )}
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: "400",
                    fontSize: "28px",
                  }}
                >
                  Información
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  loading={isUpdateCregSuscribers}
                  sx={{ width: "90%" }}
                  variant="contained"
                  color="primary"
                  onClick={handleButtonClick}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <EditOutlinedIcon sx={{ fontSize: "17px" }} />
                    Editar información
                  </Typography>
                </LoadingButton>
              </Grid>
              <IdsInformation
                frontiers={frontiers?.items[0] ?? null}
                service={service}
                index={1}
                formik={formik}
                disabled={service == null ? false : true}
              />
            </Grid>
            {steps[1].component}
            {steps[2].component}
            {steps[3].component}
            {steps[4].component}
          </FormikProvider>
        </>
      )}

      <ModalEnerbitESS
        onClose={handleClose}
        open={open}
        onOk={() => {
          handleClose();
          const contentStepper = document.getElementById(
            "modal-dialog-content"
          );
          if (contentStepper) {
            const parent = contentStepper.parentElement?.parentElement;
            if (parent) {
              parent.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }
          dispatch(onNextPageRequested());
          return;
        }}
      />
    </>
  );
};

export interface StepData {
  label: string;
  component: JSX.Element;
}
